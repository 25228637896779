import Vue from 'vue';
import { namespace as classNamespace } from 'vuex-class';
import { VuexDecorator, BindingOptions } from 'vuex-class/lib/bindings';

type StoreAction = (context: {
  commit: (action: keyof IVuexStore['actions']) => void;
  state: IVuexStore['state'];
}) => void;

type StoreMutation = (mutation: IVuexStore['mutations']) => void;
type StoreGetter = (getter: keyof IVuexStore['getters']) => void;

export interface IVuexStore {
  state: {
    [prop: string]: any;
  };
  actions: {
    [action: string]: StoreAction;
  };
  mutations: {
    [mutation: string]: StoreMutation;
  };
  getters: {
    [getter: string]: StoreGetter;
  };
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface AsVuexStore<
  S extends IVuexStore['state'],
  G extends keyof IVuexStore['getters'],
  A extends keyof IVuexStore['actions'],
  M extends keyof IVuexStore['mutations']
> {
  state: { [prop in keyof S]: S[prop] };
  getters: { [prop in G]: StoreGetter };
  actions: { [prop in A]: StoreAction };
  mutations: { [prop in M]: StoreMutation };
}

interface IBindingHelper<T> {
  <V extends Vue>(proto: V, key: keyof T): void;
  (type: keyof T, options?: BindingOptions): VuexDecorator;
}

export declare type StateTransformer<T> = (state: T, getters: any) => any;

export interface IStateBindingHelper<T> extends IBindingHelper<T> {
  (type: StateTransformer<T>, options?: BindingOptions): VuexDecorator;
}

interface IBindingHelpers<T extends IVuexStore> {
  Action: IBindingHelper<T['actions']>;
  Getter: IBindingHelper<T['getters']>;
  Mutation: IBindingHelper<T['mutations']>;
  State: IStateBindingHelper<T['state']>;
}

export function namespace<T extends IVuexStore>(storeName: string): IBindingHelpers<T> {
  return (classNamespace(storeName) as any) as IBindingHelpers<T>;
}
